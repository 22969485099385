export class EndpointService {

    //private static DriverOnboarding = 'http://localhost/TPBService/DriverOnboarding.svc';
    private static DriverOnboarding = 'https://papp-staging.autocab.net/DriverOnboarding.svc';
    //private static DriverOnboarding = 'https://papp.autocab.net/DriverOnboarding.svc';

    public static IsDriverOnboardingEnabled = EndpointService.DriverOnboarding + "/IsDriverOnboardingEnabled";
    public static GetSettings = EndpointService.DriverOnboarding + "/GetSettings";
    public static Authenticate = EndpointService.DriverOnboarding + "/Authenticate";
    public static GetCountryCodes = EndpointService.DriverOnboarding + "/GetCountryCodes";
    public static GetAgentCulture = EndpointService.DriverOnboarding + "/GetAgentCulture";
    public static SendResetPasswordLink = EndpointService.DriverOnboarding + "/SendResetPasswordLink";
    public static Register = EndpointService.DriverOnboarding + "/Register";
    public static RegisterWithValidate = EndpointService.DriverOnboarding + "/RegisterWithValidate";
    public static GetApplication = EndpointService.DriverOnboarding + "/GetApplication";
    public static SaveValue = EndpointService.DriverOnboarding + "/SaveValue";
    public static SubmitApplication = EndpointService.DriverOnboarding + "/SubmitApplication";
    public static GetApplicationStatus = EndpointService.DriverOnboarding + "/GetApplicationStatus";
    public static CancelApplication = EndpointService.DriverOnboarding + "/CancelApplication";

    constructor() {
    }
}